'use client'
import { useCallback } from 'react'
import { MS_PER_DAY } from '../constants'
import Timegrid, { TimegridProps } from '../timegrid'
import useCalendarItems from './useCalendarItems'
import useItemContextMenu from '@/hooks/main/category/useItemContextMenu'
import { useUpdatePlannerItemMutation } from '@/redux/features/api'
import useGlobalForm from '@/hooks/useGlobalForm'
import useRemoveItem from '@/hooks/main/category/useRemoveItem'

export interface PlandaTimegridProps extends Omit<TimegridProps, 'data' | 'handleAddItem' | 'handleDoubleClickDate' | 'handleDateChange'> {
    firstDay: number
    style?: any
}

const PlandaTimegrid = ({ firstDay, days, style, ...props }: PlandaTimegridProps) => {
    const { calendarFormat, add } = useCalendarItems(firstDay, firstDay + days * MS_PER_DAY)
    const { remove } = useRemoveItem()
    const [updateItem] = useUpdatePlannerItemMutation()
    const { createItemContextMenu } = useItemContextMenu()

    const { openEditItemForm } = useGlobalForm({ type: 'event' })

    // const add = useCallback((a: Omit<CalendarItem, 'id'>)=>addDated(a), [])
    const edit = useCallback((a: string, b: any) => updateItem({ id: a, updates: { set: b } }), [updateItem])

    return (
        <>
            <Timegrid
                containerCss={style}
                firstDay={firstDay}
                days={days}
                data={calendarFormat || []}
                handleAddItem={add}
                handleDoubleClickDate={openEditItemForm}
                handleRemoveItem={remove}
                // @ts-expect-error
                contextMenu={createItemContextMenu} // NOTE: difference between Item and Calendar item is name not requried in TemplateRecur
                handleDateChange={edit}
                {...props}
            />
        </>
    )
}

export default PlandaTimegrid
