import { useRemoveFromWorkBlockMutation } from '@/redux/features/api'
import ContextMenu from '@/components/common/ContextMenu'
import { TrashIcon } from '@radix-ui/react-icons'
import React from 'react'
import { Task } from 'src/types'
import { WorkBlockInfo } from 'src/types/workBlock'

const WorkBlockItem = ({ task, workBlockInfo }: { task: Task, workBlockInfo: WorkBlockInfo }) => {
    const [removeFromWorkBlock] = useRemoveFromWorkBlockMutation()

    return (
        <ContextMenu units={[
            // {
            //     label: task.completed ? "Not done yet" : 'Complete task',
            //     rightSlot: <Pencil1Icon />,
            //     onSelect: handleEdit, // TODO should open up a form, or switch tabs if inForm=true
            // },
            {
                label: `Remove ${task.name} from work block`,
                rightSlot: <TrashIcon />,
                onSelect: () => removeFromWorkBlock({ id: workBlockInfo.id, taskIds: [task.id]}),
            },
        ]} key={task.id}>
            <li className='cursor-pointer' key={task.id}
                style={{ textDecoration: task.completed ? 'line-through' : 'none' }}
            >
                {task.name}
            </li>
        </ContextMenu>
    )
}

export default WorkBlockItem