import { ContextMenuUnit } from '@/components/common/ContextMenu'
import { addDays, startOfDay } from 'date-fns'
import { groupBy, range } from 'lodash'
import React, { useMemo } from 'react'
import { styled } from 'styled-system/jsx'
import { CalendarItem } from '../../types'
import AllDayBox from './AllDayBox'

/**
 * TODO: Make draggable
 * @param param0
 * @returns
 */
const TaskArea = ({ firstDay, days, tasks, calendarId, contextMenu, isCompact }: {
    firstDay: Date | number, days: number, tasks: CalendarItem[], calendarId: string, contextMenu?: (originalItem: CalendarItem, calendar: CalendarItem) => ContextMenuUnit[],
    isCompact?: boolean
}) => {

    const tasksByDay = useMemo(() => {
        const dayMap = groupBy(tasks, (task) => startOfDay(task.dateStart).getTime())
        return dayMap && range(days).map(i => {
            const start = startOfDay(addDays(firstDay, i))
            return { date: start, tasks: (dayMap[start.getTime()] || []) as CalendarItem[] }
        })
    }, [tasks])

    if (tasks.length === 0) return <></>
    return (
        <DayBoxes>
            <DayBoxesLabel isCompact={isCompact}>
                Tasks/<br />All-day
            </DayBoxesLabel>
            <Items>
                {
                    tasksByDay?.map(({ tasks, date }, i) => {
                        return (
                            <AllDayBox contextMenu={contextMenu} calendarId={calendarId} key={i} pos={i} items={tasks} date={date} />
                        )
                    })
                }
            </Items>
        </DayBoxes>
    )
}

export default TaskArea

const DayBoxesLabel = styled('div', {
    base: {
        width: `calc({sizes.widthOfHourLabelsSmall} - {borderWidths.$medium})`,
        center: 'row',
        textAlign: 'center',
        '@bp1': {
            width: `calc({sizes.widthOfHourLabels} - {borderWidths.$medium})`,
        },
    },
    variants: {
        isCompact: {
            true: {
                width: `calc({sizes.widthOfHourLabelsSmall} - {borderWidths.$medium})`,
            }
        }
    }
})

const DayBoxes = styled('div', {
    base: {
        display: "flex",
        alignItems: "stretch",
        resize: "vertical",
        overflow: "auto",
        maxHeight: "20%",
        border: "$border",
        borderRight: "none",
        boxSizing: "border-box",
        position: "relative",
        fontSize: 12,
    }
})

const Items = styled('div', {
    base: {
        display: "flex",
        flex: 1,
        alignItems: "stretch",
        justifyContent: "stretch",
        gap: "inherit"
    }
})
