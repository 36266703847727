'use client'
import { DB_FORMAT_DAY } from '@constants/date'
import { format, startOfDay } from 'date-fns'
import EventCronParser from 'event-cron-parser'
import { keyBy } from 'lodash'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useStateWithDeps } from 'use-state-with-deps'
import { CalendarEvent, CalendarItem, CalendarTask, DayOfWeek, isMultiDayEvent, isMultiWeekEvent, isRecurringEvent } from '../types'
import { itemIsWithinInterval, splitEventByDateUnit } from '../utils'
import { MS_PER_MINUTE } from '../constants'

// multiday+recur proxies are just regular events, but their id points back to their parent (disjoint set structure, but with dict)
// disjoint sets to represent events
/**
 *
 * @param events each event must have a unique id
 * @returns
 */
export default function useData(data: CalendarItem[] = [], start: Date, end: Date, splitEventsBy: 'day' | 'week', weekStartsOn = 1 as DayOfWeek) {
    weekStartsOn = (weekStartsOn % 7) as DayOfWeek

    const [events, setEvents] = useStateWithDeps<CalendarEvent[]>(data.filter((x) => x.cron || (!!x.dateEnd && itemIsWithinInterval(x, start, end))) as CalendarEvent[], [data, start, end])
    const [tasks, setTasks] = useStateWithDeps<CalendarTask[]>(data.filter((x) => !x.dateEnd && itemIsWithinInterval(x, start, end)) as CalendarTask[], [data, start, end])

    const [calEvents, setCalEvents] = useState<CalendarEvent[]>([])
    // parentId is sorta like a disjoint set structure, but with a dict + root has size in positive number
    // initializing two data structures in useMemo (parentId+calEvents)
    const [eventSet, setEventSet] = useState<ParentSet>(new ParentSet({}))

    useEffect(() => {
        initEventSet(events)
    }, [events, start, end, weekStartsOn])

    // (eventSet.get(item.id).id || item.id)
    // const get = (id: string) => eventSet.get(id) || tasks.find((x) => x.id === id)

    function initEventSet(events: CalendarEvent[]) {
        const parentSet = new ParentSet(keyBy([...events], (x) => x.id))
        const ces = [...events].reduce((prev, cur) => {
            const vals = convert(cur, undefined, parentSet)

            return [...prev, ...vals]
        }, [] as CalendarEvent[])

        setCalEvents(ces)
        setEventSet(parentSet)
    }

    function editCalItem(id: string, updates: { [x: string]: any }) {
        delete updates.id
        if (eventSet.get(id)) {
            const newEvent = { ...eventSet.get(id), ...updates }
            const newEvents = events.map((e) => (e.id === newEvent.id ? newEvent : e))
            setEvents(newEvents)
            initEventSet(newEvents)
        } else {
            const newTasks = tasks.map((e) => (e.id === id ? { ...e, ...updates } : e))
            setTasks(newTasks)
        }
    }

    function addCalItem(item: CalendarItem) {
        if ('dateEnd' in item || item.cron) {
            const newEvents = [...events, item as CalendarEvent]
            setEvents(newEvents)
            initEventSet(newEvents)
        } else setTasks([...tasks, item as CalendarTask])
    }

    function find(id: string) {
        return eventSet.get(id) || tasks.find((x) => x.id === id)
    }

    // used in reduce above
    function convert(event: CalendarEvent, parent?: string, parentSet: ParentSet = eventSet, callback?: (id: string, parentId?: string) => void): CalendarEvent[] {
        parentSet.add(event.id, parent)
        if (isRecurringEvent(event)) {
            const { cron, id, ...rest } = event // take cron out so child won't call recursive function
            try {
                // TODO steal from api
                // Reucrring events should be processed twice for recurring multiday events (recursion)
                // need a date range to use EventCronParser
                const cronParser = new EventCronParser(cron, event.dateStart, event.dateEnd)
                let { duration } = cronParser.parsedCron
                if (duration === 0) duration = MS_PER_MINUTE * 10
                let dates = cronParser.range(start, end)
                if (event.excludedDays) {
                    dates = dates.filter((d) => {
                        return !event.excludedDays?.has(format(startOfDay(d), DB_FORMAT_DAY))
                    })
                }

                return dates.reduce((prev, date): CalendarEvent[] => {
                    const id = event.id + nanoid(6)
                    const item = {
                        ...rest,
                        dateStart: date.getTime(),
                        dateEnd: date.getTime() + duration,
                        id,
                    }
                    return [...prev, ...convert(item, event.id, parentSet)] // item added to parentIdSet in convert() call
                        .filter((x) => itemIsWithinInterval(x, start, end))
                }, [] as CalendarEvent[])
            } catch (e) {
                console.error('convert', cron, id)
                return []
            }
        }
        // const isSame = splitMultiDayBy == 'day' ? isSameDay : (d1: Date, d2)=> isSameWeek(d1, d2, {weekStartsOn})
        if ((splitEventsBy === 'day' && isMultiDayEvent(event)) || (splitEventsBy === 'week' && isMultiWeekEvent(event))) {
            parentSet.addToDict(event.id, { ...event })
            const events = splitEventByDateUnit(splitEventsBy, event)
            events.forEach((e) => parentSet.add(e.id, event.id))
            return events
        }
        // if not multi-day need to trim dateEnd to end of day
        // event.dateEnd = Math.min(event.dateEnd, endOfDay(event.dateStart).getTime())
        return [event]
    }

    return {
        calEvents, // all unique ids, to reach original item go from parentIdSet
        tasks,
        setCalEvents,
        eventSet,
        editCalItem,
        addCalItem,
        find,
    }
}

export class ParentSet {
    parentMap: { [x: string]: string | number }
    dict: { [id: string]: any }

    constructor(dict: { [id: string]: any }) {
        this.parentMap = {}
        this.dict = dict
    }

    addToDict(key: string, value: any) {
        this.dict[key] = value
    }

    get(id: string) {
        if (id in this.parentMap) return this.dict[this.#findRoot(id)] as any
        return undefined
    }

    getParent(id: string) {
        return this.dict[this.#findParent(id)]
    }

    add(id: string, parent?: string) {
        if (!parent) {
            // addRoot
            if (id in this.parentMap) throw new Error('cannot add existing element ' + id)
            this.parentMap[id] = 1
        } else {
            // addChild
            if (!(parent in this.parentMap)) throw new Error('parent not in set')
            this.parentMap[id] = parent
            // find root of parent to update size
            this.parentMap[this.#findRoot(parent)] = (this.parentMap[this.#findRoot(parent)] as number) + 1
        }
    }

    #findRoot(id: string) {
        let rootId: string = id
        while (!this.isRoot(rootId)) rootId = this.parentMap[rootId] as string
        return rootId
    }

    // one is ancestor of itself
    #isAncestor(ancestor: string, child: string) {
        if (!this.isSameGroup(ancestor, child)) return
        let rootId: string = child
        while (rootId !== ancestor && !this.isRoot(rootId)) rootId = this.parentMap[rootId] as string
        return rootId === ancestor
    }

    #findParent(child: string) {
        return this.isRoot(child) ? child : this.parentMap[child]
    }

    isRoot(id: string) {
        return typeof this.parentMap[id] === 'number'
    }

    isSameGroup(id1: string, id2: string) {
        return this.#findRoot(id1) == this.#findRoot(id2)
    }

    updateDict(id: string, updates: { [x: string]: any }) {
        id = this.#findRoot(id)
        this.dict[id] = { ...this.dict[id], ...updates }
    }

    removeGroup(id: string) {
        const toDelete: string[] = []
        Object.keys(this.parentMap).forEach((key) => {
            if (this.isSameGroup(id, key)) toDelete.push(key)
        })
        toDelete.forEach((key) => delete this.parentMap[key])
    }

    remove(id: string) {
        const toDelete: string[] = []
        Object.keys(this.parentMap).forEach((key) => {
            if (this.#isAncestor(id, key)) toDelete.push(key)
        })
        toDelete.forEach((key) => delete this.parentMap[key])
    }

    // copy(set: ParentSet) {
    //     return new ParentSet(this.dict, this.parentMap)
    // }
}
