import React from 'react'
import { styled } from 'styled-system/jsx'
import { WIDTH_OF_HOUR_LABELS, WIDTH_OF_HOUR_LABELS_SMALL } from '../../constants'
import { differenceInCalendarDays } from 'date-fns'
import useRemToPx from '@/hooks/useRemToPx'

const radius = 7
// const DIFF = radius - 1.5
const TimeBar = ({ width, height, firstDay, isCompact }: { width: number, height: number, firstDay: Date | number, isCompact?: boolean }) => {
    const labelWidth = useRemToPx(Number((!isCompact ? WIDTH_OF_HOUR_LABELS : WIDTH_OF_HOUR_LABELS_SMALL).slice(0, -3)))
    const { x, y } = dateToPixels(new Date(), width, height, firstDay, labelWidth)
    // if firstDay is not same week as today, don't show
    if (Math.abs(differenceInCalendarDays(new Date(), firstDay)) > 6) return null
    return (
        <Container style={{ width, top: y + height, left: x }}>
            <Bar />
            <Circle />
        </Container>
    )
}

export default TimeBar

function dateToPixels(date: Date, cellWidth: number, cellHeight: number, firstDay: Date | number, leftOffset = 0) {
    const days = differenceInCalendarDays(date, firstDay)
    const x = days * cellWidth + leftOffset
    const y = cellHeight * 2 * date.getHours() + cellHeight * date.getMinutes() / 30
    return { x, y }
}

const Container = styled('div', {
    base: {
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        position: "relative",
        height: "max-content"
    }
})

const Bar = styled('div', {
    base: {
        position: "absolute",
        backgroundColor: "$secondary9",
        width: "100%",
        height: 3,
        zIndex: 2,
        boxShadow: "$boxShadow"
    }
})

const Circle = styled('div', { base: {
    position: 'absolute',
    borderRadius: '50%',
    zIndex: 2,
    backgroundColor: '$secondary9',
    width: radius * 2,
    height: radius * 2,
    left: -radius,
    boxShadow: '$boxShadow'
} })