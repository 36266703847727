import { addDays, differenceInHours, startOfDay } from "date-fns"
import EventCronParser from "event-cron-parser"
import { groupBy, range } from "lodash"
import { useMemo } from "react"
import { MS_PER_HOUR } from "../../constants"
import { ParentSet } from "../../hooks/useData"
import { CalendarEvent } from "../../types"
import { createDayMap } from "../../utils"

export type EventLayout = (string | undefined)[][][]


const MIN_HOURS_IN_LONG_EVENT = 12
export const isLongEvent = (e: CalendarEvent) => e.cron ? new EventCronParser(e.cron).parsedCron.duration / MS_PER_HOUR >= MIN_HOURS_IN_LONG_EVENT : differenceInHours(e.dateEnd, e.dateStart) >= MIN_HOURS_IN_LONG_EVENT
/**
 * IMPORTANT: prefixes everything in layout with 0. or 1. (0. means first)
 * layout without all-day events
 * longer events are separated out so they don't take up too much space on timegrid (make them lower opacity)
 * @param events
 * @param firstDay
 * @param days number of days/columns in timegrid
 * @returns
 */
export function useLayout(events: CalendarEvent[], eventSet: ParentSet, firstDay: number, days = 1) {
    function createLayout(events: CalendarEvent[]): EventLayout {
        const dayMap = createDayMap(events)
        // if (!dayMap) return []
        const layout: EventLayout = []
        for (let i = 0; i < days; i++) {
            const start = startOfDay(addDays(firstDay, i))
            const filtered = dayMap[start.getTime()] || []
            layout.push(createColumnLayout(filtered))
        }
        return layout
    }

    const [shortLayout, longLayout] = useMemo(() => {
        const isLong = groupBy(events, e => isLongEvent(eventSet.get(e.id)))
        // condition, longer than 6 hours?
        // TODO: check that isLong keys are indeed boolean to string
        return [createLayout(isLong.false), createLayout(isLong.true)]
    }, [events])

    return {
        shortLayout,
        longLayout
    }
}

function getCellNum(date: Date) {
    return date.getHours() * 2 + Math.floor(date.getMinutes() / 30)
}

function createColumnLayout(data: { dateStart: Date | number, dateEnd: Date | number, [x: string]: any }[] | undefined) {
    const BLOCKS_PER_COLUMN = 24 * 2
    const layout = range(BLOCKS_PER_COLUMN).map(() => [] as (string | undefined)[])
    if (!data) return layout

    data.forEach((item) => {
        if (!('dateEnd' in item && item.dateEnd)) return
        const dateEnd = new Date(item.dateEnd).getTime()
        const start = new Date(item.dateStart)
        const end = new Date(dateEnd - 1) // -1 since end is exclusive
        const startCellNum = getCellNum(start)
        const endCellNum = getCellNum(end)

        let i = 0
        const cellNums = range(startCellNum, endCellNum + 1)
        while (cellNums.some(x => layout[x][i])) i++;

        cellNums.forEach(x => layout[x][i] = x === startCellNum ? '0.' + item.id : '1.' + item.id)
    })

    return layout
}

const items = [
    {
        id: '4-6',
        dateStart: 1660734000000, // 4:00
        dateEnd: 1660741200000, // 6:00
    },
    {
        id: '5-8',
        dateStart: 1660737600000, // 5:00
        dateEnd: 1660748400000, // 8:00
    },
    {
        id: '6-9',
        dateStart: 1660741200000, // 6:00
        dateEnd: 1660752000000, // 9:00
    },
    {
        id: '5-6',
        dateStart: 1660737600000, // 5:00
        dateEnd: 1660741200000, // 6:00
    },
]

const ans = Array.apply(null, Array(48)).map((x, half) => {
    const h = Math.floor(half / 2)
    if (h === 4) // 4, 4:30
        return [(h === half / 2 ? '0' : '1') + '.4-6']
    if (h === 5)
        return ['1.4-6', (h === half / 2 ? '0' : '1') + '.5-8', (h === half / 2 ? '0' : '1') + '.5-6']
    if (h >= 6 && h < 8) // 6
        return [(half === 12 ? '0' : '1') + '.6-9', '1.5-8']
    if (h === 8)
        return ['1.6-9']
    return []
})

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest
    it('layout', () => {
        expect(createColumnLayout(undefined)).toStrictEqual(Array(48).fill([]))
        expect(createColumnLayout(items)).toStrictEqual(ans)
    })
}