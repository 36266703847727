import { useDroppable } from '@dnd-kit/core';
import React from 'react'
import { styled } from 'styled-system/jsx'
import { Layout } from '..';
import { MS_PER_HALF_HOUR } from '../../constants';
import { NewItemInfo } from '../../types';

const Cell = ({
    date,
    handleDoubleClick,
    calendarId,
    layout
}: {
    date: Date, // hours should be set to 0
    handleDoubleClick: (item: NewItemInfo) => void,
    calendarId: string,
    layout: Layout
}) => {
    const defaultEvent = { dateStart: date.getTime(), dateEnd: date.getTime() + MS_PER_HALF_HOUR }
    const { setNodeRef, isOver } = useDroppable({
        id: date.getTime(),
        data: {
            scope: calendarId,
            default: defaultEvent
        },
    });
    return (
        <Container isOver={isOver} ref={setNodeRef} layout={layout} onDoubleClick={() => handleDoubleClick(defaultEvent)} key={'cell_' + date.toTimeString()} isStartOfHour={date.getMinutes() === 0} isSameDayOfWeek={new Date().getDay() == date.getDay()} />
    )
}

export default Cell

const Container = styled('div', {
    variants: {
        layout: {
            default: {
                boxSizing: 'border-box',
                display: 'flex',
                borderBottom: '$borderThin',
                borderLeft: "$borderThin"
            },
            paper: {
                all: 'initial',
                position: 'relative',
                background: '$gray1',
                center: 'row',
                fontSize: '$1',
                letterSpacing: "0.18em"
            }
        },
        isOver: {
            true: {
                backgroundColor: "$timegridCellHover"
            }
        },
        isStartOfHour: {
            true: {}
        },
        isSameDayOfWeek: {
            true: {}
        }
    },
    base: {
        boxSizing: 'border-box',
        display: 'flex',
        borderBottom: '$borderThin',
        borderLeft: "$borderThin"
    },
    compoundVariants: [
        {
            layout: 'default',
            isStartOfHour: true,
            css: {
                borderBottomStyle: 'dashed',
            },
        },
        // {
        //     type: 'default',
        //     isSameDayOfWeek: true,
        //     css: {
        //         background: '$timegridbgToday',
        //     },
        // },
    ]
})
