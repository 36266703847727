import { DatedItem, NewItem } from "src/types"
import { getTypeFromId } from "@/utils/item"
import { uniq } from "lodash"
import { addDays, isBefore, isSameDay } from "date-fns"
import { useMemo } from "react"
import { useGetDatedItemsQuery } from '@/redux/features/api'
import { useSearchParams } from "next/navigation"
import { usePutPlannerItem } from "../category/usePutPlannerItem"

// TODO: might want to add categoryNames to datedItem
const useDated = (start: number, end: number, convertTemplateRecurs = false) => {
    const { data: beforeFilter, isLoading, error } = useGetDatedItemsQuery({ start, end, convertTemplateRecurs })
    const { putItem } = usePutPlannerItem()

    const searchParams = useSearchParams()
    // const { types, categories, sections, days, priorities }
    const types = searchParams?.get('types')
    const categories = searchParams?.get('categories')
    const sections = searchParams?.get('sections')
    const days = searchParams?.get('days')
    const priorities = searchParams?.get('priorities')

    async function add(item: Partial<DatedItem>) {
        if (!item) return;
        const newItem = { ...item, ...(!item.type && !item.id && ('dateEnd' in item && item.dateEnd ? { type: 'event' } : { type: 'task' })) }
        putItem(newItem as NewItem)
        // await mutate(castPromiseToUndefined(fetchPutItem(newItem as NewItem)), {
        //     populateCache: false,
        //     optimisticData: x => {
        //         if (!x) return []
        //         x.push(item as DatedItem) // TODO: make default item
        //         return [...x]
        //     }
        // })
    }

    const data = useMemo(() => {
        return beforeFilter?.filter(item => {
            return checkType(item, types) && checkCategory(item, categories) && checkSection(item, sections) && checkDays(item, days) && checkPriorities(item, priorities)
        })
    }, [beforeFilter, categories, days, priorities, sections, types])

    return {
        data,
        isLoading: !error && !data,
        isError: !!error,
        add,
    }
}
export default useDated


function checkCategory(item: DatedItem, categories: unknown) {
    if (!categories || typeof categories !== 'string') return true
    return categories.split(',').some(categoryId => item.category.startsWith(categoryId))
}

function checkSection(item: DatedItem, sections: unknown) {
    if (!sections || typeof sections !== 'string') return true
    return sections.split(',').some(sectionId => item.category.startsWith(sectionId))
}

function checkType(item: DatedItem, types: unknown) {
    if (!types || typeof types !== 'string') return true
    return types.split(',').some(type => getTypeFromId(item.id) === type)
}

function checkPriorities(item: DatedItem, priorities: unknown) {
    if (!priorities || typeof priorities !== 'string') return true
    return priorities.split(',').some(priority => parseInt(priority) === item.priority)
}

function checkDays(item: DatedItem, days: unknown) { // does not handle recurring events
    if (!days || typeof days !== 'string') return true
    const itemDaysOfWeek = [new Date(item.dateStart).getDay()]
    if ('dateEnd' in item && item.dateEnd) {
        let start = addDays(item.dateStart, 1);
        while (isBefore(start, item.dateEnd) || isSameDay(start, item.dateEnd)) {
            // console.log(item, itemDaysOfWeek)
            itemDaysOfWeek.push(start.getDay())
            start = addDays(start, 1)
        }
    }
    return uniq(days.split(',')).map(x => parseInt(x)).some(day => {
        return itemDaysOfWeek.includes(day)
    })
}
