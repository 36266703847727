import { addDays, format } from 'date-fns'
import { range } from 'lodash'
import { css } from 'styled-system/css';
import React from 'react'
import { styled } from 'styled-system/jsx'

const DayOfWeekLabels = ({ firstDay, days, weekday = 'long' }: { firstDay: Date | number, days: number, weekday?: "long" | "short" | "narrow" }) => {
    const dayOfWeekLabelProps = range(days).map(day => { // day is 0 - 6 i think?
        const dayOfWeekDay = addDays(firstDay, day)
        const label = dayOfWeekDay.toLocaleString(undefined, { weekday: weekday })
        return { dayOfWeek: dayOfWeekDay.getDay(), label, date: dayOfWeekDay }
    })

    return (
        <Container>
            {dayOfWeekLabelProps.map(x => (
                <Label isToday={new Date().getDay() === x.dayOfWeek} key={x.dayOfWeek}>{x.label}<sub className={css({ marginLeft: 5 })}>{format(x.date, "d")}</sub></Label>))}
        </Container>
    )
}

export default DayOfWeekLabels

const Label = styled('label', {
    variants: {
        isToday: {
            true: {
                color: "$primary11"
            }
        }
    },
    base: {
        minWidth: 0,
        overflow: "hidden",
        textOverflow: "clip",
        position: "relative",
        gridRow: 1,
        center: "row"
    }
})

const Container = styled('section', {
    base: {
        gridArea: 'daysOfWeek',
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        position: 'sticky',
        width: '100%',
        top: 0,
        height: 'HEIGHT_OF_TIMEGRID_CELL_IN_REM',
        zIndex: 5, // has to be at least 10 so rnd will go under
        border: '$borderThin',
        borderRight: 'none',
        background: 'inherit'
    }
})