'use client'
import { startOfDay, addMinutes, addDays, nextDay } from 'date-fns'
import { times } from 'lodash'
import useRemToPx from 'src/hooks/useRemToPx'
import { styled } from 'styled-system/jsx'
import DayOfWeekLabels from './components/DayOfWeekLabels'
import Event from './components/Event'
import { useRnd } from './hooks/useRnd'
import HourLabels from './components/HourLabels'
import { CalendarItem, NewItemInfo } from '../types'
import TaskArea from './components/TaskArea'
import useData from '../hooks/useData'
import { useMemo } from 'react'
import Cell from './components/Cell'
import { useDndMonitor } from '@dnd-kit/core'
import { endOfDay } from 'date-fns'
import { changeDay } from '@planda/utils'
import { HEIGHT_OF_TIMEGRID_CELL_IN_REM } from '../constants'
import { ContextMenuUnit } from '@/components/common/ContextMenu'
import { DayOfWeekNum, isDBEventId, isDBTemplateRecurId } from 'src/types'
import { isAllDay } from '../utils'
import { useWindowSize } from '@/hooks/useWindowWidth'
import { useCurrentState } from '@/hooks/useCurrentState'
import TimeBar from './components/TimeBar'
import { useAddToWorkBlockMutation, usePutAchievementMutation } from '@/redux/features/api'

export * from '../constants'
export type Layout = 'default' | 'paper'

/**
 * DOESN'T SUPPORT RECURRING EVENTS, recuring events must be split up first
 * TODO: recurring events
 * TODO: tasks - red line + scroll in taskarea & scale when hi
 * week starts on is controlled by first day
 */

export interface TimegridProps {
    days: number
    firstDay: Date | number
    handleAddItem: (item: Omit<CalendarItem, 'id'>) => any
    handleRemoveItem?: (id: string) => any
    handleDoubleClickDate: (day: NewItemInfo) => any
    handleDateChange: (id: string, updates: { dateStart?: number; dateEnd?: number; cron?: string }) => any
    layout?: Layout
    data: CalendarItem[]
    hideDaysOfWeek?: boolean
    calendarId?: string
    contextMenu?: (originalItem: CalendarItem, calendar: CalendarItem) => ContextMenuUnit[]
    containerCss?: any
    setIsDragging?: (isDragging: boolean) => void
    disableScroll?: boolean
    hideMeridian?: boolean
    isCompact?: boolean
    containerClassName?: string
}

const Timegrid = ({
    days,
    firstDay,
    handleAddItem,
    handleRemoveItem,
    handleDoubleClickDate,
    handleDateChange,
    layout = 'default',
    data,
    hideDaysOfWeek = false,
    calendarId = 'calendar',
    contextMenu,
    containerCss,
    containerClassName,
    setIsDragging: setIsDraggingExternal,
    disableScroll = false,
    hideMeridian,
    isCompact,
}: TimegridProps) => {
    const { windowSize } = useWindowSize()
    const [addToWorkBlock] = useAddToWorkBlockMutation()
    const [putAchievement] = usePutAchievementMutation()

    if (windowSize.width && windowSize.width < 750) {
        days = Math.min(days, 3)
        firstDay = nextDay(firstDay, new Date().getDay() as DayOfWeekNum).getTime()
    } else if (windowSize.width && windowSize.width < 900) {
        days = Math.min(days, 5)
        firstDay = nextDay(firstDay, new Date().getDay() as DayOfWeekNum).getTime()
    }
    const [isDragging, setIsDraggingInternal, isDraggingRef] = useCurrentState(false)

    const setIsDragging = (isDragging: boolean) => {
        setIsDraggingExternal?.(isDragging)
        setIsDraggingInternal(isDragging)
    }

    const startDate = useMemo(() => startOfDay(firstDay), [firstDay])
    const endDate = useMemo(() => endOfDay(addDays(firstDay, days - 1)), [firstDay, days])

    const CELL_HEIGHT = useRemToPx(HEIGHT_OF_TIMEGRID_CELL_IN_REM)

    const { calEvents, tasks, editCalItem, addCalItem, eventSet, find } = useData(
        data,
        startDate,
        endDate,
        'day',
        new Date(firstDay).getDay() as DayOfWeekNum
    )
    const { dragAreaRef, rndProps, invisibleProps, cellWidth } = useRnd(
        calEvents.filter((x) => !isAllDay(x)),
        editCalItem,
        eventSet,
        startDate.getTime(),
        days,
        CELL_HEIGHT,
        handleDateChange,
        setIsDragging,
        { isDraggingRef, handleDoubleClickDate, handleRemoveItem }
    )

    const taskAreaItems: CalendarItem[] = [...tasks, ...calEvents.filter((x) => isAllDay(x))]

    useDndMonitor({
        onDragStart(event) {
            setIsDragging(true)
        },
        onDragMove(event) {},
        onDragOver(event) {},
        onDragEnd(event) {
            setIsDragging(false)
            /** THIS IS FOR DRAGGING TASKS/TASK_EVENTS OR CATEGORIES/WORK_BLOCKS NOT EVENTS */
            const { active, over, delta } = event
            if (!over || !over.data.current || !active.data.current || typeof active.id !== 'string') return
            if (over.data.current.scope !== calendarId) return

            // if (active.data.current.isWorkBlock) {
            //     console.log('work block', active.data)
            //     return
            // }

            if (typeof over.id === 'string' && (isDBEventId(over.id) || isDBTemplateRecurId(over.id, 'event'))) {
                const taskId = active.id
                const { scope, type, name, ...rest } = active.data.current
                addToWorkBlock({
                    id: over.id,
                    taskId,
                    taskInfo: { ...rest, type: 'task', name: name.startsWith('Work on ') ? name.slice('Work on '.length) : name },
                })
                // userId, id: WORK_BLOCK.i.event.aidfojajdoa, taskIds: []
                // date must be in ID, due to recurring events
                // but what if you move work block around?
                putAchievement({ achievementId: 'workblock-create-by-drag-category' })
                return
            }

            const calItem = find(active.id) as CalendarItem | undefined
            const overData = over.data.current
            const activeData = active.data.current
            // for dragging task into taskArea
            if (!calItem) {
                // taskEvent
                const item = { ...overData.default, ...activeData }
                const { ...putItem } = item // keep name in, so event can have separate name
                handleAddItem({ ...putItem })
                addCalItem({ ...item })
                putAchievement({ achievementId: 'drag-task-into-calendar' })
            } else {
                // task
                const updates = { dateStart: changeDay(calItem.dateStart, overData.start).getTime() }
                handleDateChange(calItem.id, { ...updates })
                editCalItem(calItem.id, { ...updates })
            }
        },
        onDragCancel(event) {},
    })

    const isCompactLayout = isCompact || (!!windowSize.width && windowSize.width < 500)

    return (
        <Container className={containerClassName} style={containerCss}>
            <Calendar isCompact={isCompactLayout}>
                {!hideDaysOfWeek && (
                    <DayOfWeekLabels weekday={windowSize.width && windowSize.width < 900 ? 'short' : 'long'} firstDay={startDate} days={days} />
                )}
                <HourLabels
                    isCompact={isCompactLayout}
                    hideMeridian={hideMeridian}
                    disableScroll={disableScroll}
                    showHourBorder={layout !== 'paper'}
                />
                <Grid ref={dragAreaRef} style={{ gridTemplateColumns: `repeat(${days}, 1fr)` }}>
                    <div {...(invisibleProps as any)} />
                    {/* <Rnd {...invisibleProps} /> */}
                    {times(48 * days, (i) => {
                        const date = addMinutes(startDate, 30 * i)
                        return <Cell key={i} date={date} handleDoubleClick={handleDoubleClickDate} calendarId={calendarId} layout={layout} />
                    })}
                    {/* {
                            // TODO: tasks line in timegrid
                            tasks.map(t=> {
                                return <TaskCircle style={{ top: CELL_HEIGHT }} />
                            })
                        } */}

                    {rndProps?.map((x, i) => (
                        <Event
                            calendarId={calendarId}
                            setIsDragging={setIsDragging}
                            rootEvent={eventSet.get(x.event.id)}
                            contextMenu={contextMenu}
                            key={x.rndProps.key}
                            {...x}
                        />
                    ))}
                </Grid>
                {/* TimeBar isCompact!!windowSize.width && windowSize.width < 640 */}
                <TimeBar isCompact={isCompactLayout} width={cellWidth} firstDay={firstDay} height={CELL_HEIGHT} />
            </Calendar>
            {taskAreaItems.length > 0 && (
                <TaskArea
                    isCompact={isCompactLayout}
                    contextMenu={contextMenu}
                    firstDay={startDate}
                    days={days}
                    tasks={taskAreaItems}
                    calendarId={calendarId}
                />
            )}
        </Container>
    )
}

export default Timegrid

const TaskCircle = styled('div', {
    base: {
        position: 'absolute',
        borderRadius: '50%',
        size: '10px',
        color: '$important',
        backgroundColor: '$important',
    },
})

const Container = styled('div', {
    base: {
        gridArea: 'calendar',
        background: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: 'stretch',
        borderRight: '$border',
        boxSizing: 'border-box',
        minWidth: 0,
        minHeight: 0,
    },
})

const Calendar = styled('div', {
    base: {
        background: 'inherit',
        overflowY: 'scroll',
        position: 'relative',
        display: 'grid',
        gridTemplateAreas: `
            '.... daysOfWeek'
            'hours grid'`,
        gridTemplateColumns: `{sizes.widthOfHourLabelsSmall} 1fr`,
        gridTemplateRows: '1fr',
        boxSizing: 'border-box',
        minWidth: 0,
        minHeight: 0,
        flex: 1,
        '@bp1': {
            gridTemplateColumns: `{sizes.widthOfHourLabels} 1fr`,
        },
    },
    variants: {
        isCompact: {
            true: {
                '@bp1': {
                    gridTemplateColumns: `{sizes.widthOfHourLabelsSmall} 1fr`,
                },
            },
        },
    },
})

const Grid = styled('div', {
    base: {
        display: 'grid',
        width: '100%',
        gridTemplateRows: `repeat(48, {sizes.HEIGHT_OF_TIMEGRID_CELL_IN_REM})`,
        gridAutoFlow: 'column',
        position: 'relative',
        gridArea: 'grid',
        boxSizing: 'border-box',
        background: '$timegridbg',
    },
})
